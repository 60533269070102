import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
//import "./assets/estilo.css"
import Vuelidate from "vuelidate";
import { BSpinner } from "bootstrap-vue";

import "./interceptor";
import "vue-search-select/dist/VueSearchSelect.css";
// import Autocomplete from '@trevoreyre/autocomplete-vue'
// import '@trevoreyre/autocomplete-vue/dist/style.css'
import VueTypeaheadBootstrap from "vue-typeahead-bootstrap";
import VueBootstrapTypeahead from "vue-bootstrap-typeahead";
import VueBootstrapAutocomplete from "@vue-bootstrap-components/vue-bootstrap-autocomplete";

import * as VueGoogleMaps from "vue2-google-maps";
import VueAnalytics from "vue-analytics";
import VueGtag from "vue-gtag";
import "./assets/custom.css";
// Vue.config.productionTip = false
// Configuration VueAnalytics
Vue.use(
  VueGtag,
  {
    config: {
      id: "G-9BG8Z7ZBSW",
      params: {
        send_page_view: false,
      },
    },
  },
  router
);

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE,
    libraries: "places",
    language: "es-419", // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },
  installComponents: true,
});

Vue.config.productionTip = false;

Vue.use(Vuelidate);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.component("b-spinner", BSpinner);
Vue.component("vue-typeahead-bootstrap", VueTypeaheadBootstrap);
Vue.component("vue-bootstrap-typeahead", VueBootstrapTypeahead);
Vue.component("vue-bootstrap-autocomplete", VueBootstrapAutocomplete);

new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount("#app");
