<template> 
    <component :is="layout"></component>  
</template>

<script>

    import LayoutExterno1 from './components/layout/Layout-externo1.vue'
    import LayoutLogout from './components/layout/Layout-logout.vue'
     import { mapState } from 'vuex'

    export default {
    name: 'app',
    components : { LayoutExterno1, LayoutLogout },
    computed: mapState(['layout']),
    data(){
        return {
            // layout: 'LayoutExterno1'
        }
    }
    }
</script>

