import FileSaver from 'file-saver'
export const seePDFServel = async(documentos) =>
{
    for(const arch of documentos)
    { 
        // const downloadLink = document.createElement("a")
        // downloadLink.download = arch.filename 
        // downloadLink.setAttribute('target', '_blank')   
        // downloadLink.href = `data:application/pdf;base64,${arch.content}` 
        // document.body.appendChild(downloadLink)
        // downloadLink.click()   
        // document.body.removeChild(downloadLink)
        // openBase64InNewTab(arch.content, 'application/pdf');

        // var blob = new Blob(["Hello, world!"], {type: "text/plain;charset=utf-8"}); 
        const blob = await base64ToBlob(arch.content, 'application/pdf')
        FileSaver.saveAs(blob, arch.filename)

    }
    
}

function openBase64InNewTab (data, mimeType) {
    var byteCharacters = atob(data);
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    var file = new Blob([byteArray], { type: mimeType + ';base64' });
    var fileURL = URL.createObjectURL(file);
    window.open(fileURL);
}

export const seePDF = async(documentos) =>
{
    for(const arch of documentos)
    {
        const contentType = 'application/pdf'
        let  b64Data = JSON.parse(arch.documento)

        b64Data = b64Data.base64.split(',')[1] 
        const blob = await base64ToBlob(b64Data, contentType)
        
        const url = window.URL.createObjectURL(blob) 
        try {
            window.open(url,'_blank') 
            
        } catch (error) {
            console.error('Error en la funcion seePDF::', error)
        }
    }

}


export const downloadPDF = async(documentos) =>
{
    for(const arch of documentos)
    {
        if(!arch.documento) continue

        const pdf = JSON.parse(arch.documento)  
        const downloadLink = document.createElement("a")
        downloadLink.download = pdf.name
        downloadLink.href = pdf.base64;
        downloadLink.click()  
    }

}
export const downloadPDFServel = async(documentos) =>
{
    for(const arch of documentos)
    {
        if(!arch.documento) continue 
        
        const downloadLink = document.createElement("a")
        downloadLink.download = arch.rol
        downloadLink.href = arch.documento_servel
        downloadLink.click()  
    }

}



async function base64ToBlob(b64Data, contentType='', sliceSize=512) 
{ 
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob 

}