import axios from 'axios'
import store from './../store'
import router from './../router'
import UserInfoStore from '../app/user-info-store'
import UserInfoApi from '../app/user-info-api' 
import auth from '../app/auth' 


 

export const  user_registro  = async (to, from, next) => {   

    try { 

          
    if (!auth.auth.isUserSignedIn()) 
    {
        UserInfoStore.setLoggedIn(false)
        return next({
            path: '/auth/login',
            query: { redirect: to.fullPath }
        })
    

    }


    UserInfoApi.getUserInfo()
    .then(async response => {
        UserInfoStore.setLoggedIn(true)
        UserInfoStore.setCognitoInfo(response)
        const url = `https://api.servel.cl/private/registroavanzado`
 
        const { data: { datos, error , statusCode} } = await axios.post(url) 
 
        if(statusCode !=200)
        {

            await store.dispatch('solicitud/setUsuarioNoExiste', true) 
            
            return router.push({name: 'Redirect'}) 


        }
        
        await store.dispatch('solicitud/setUsuarioNoExiste', false) 
        await store.dispatch('registro/setRegistro', datos) 
        next()
    }) 


    } catch (error) {

        console.error('Error al consultar por el usuario en el registro::', error)
        next({ path: '/solicitudes'}) 
    } 

}  