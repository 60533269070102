<template>  
<div>
    <b-container v-if="loading" class="text-center">
        <b-spinner variant="primary" label="Spinning"></b-spinner>
    </b-container>
    <b-container v-if="!loading" class="conta"> 
        <b-row>
            <b-col> 
                <p class="h2">
                    Solicitud de Incorporación al Registro Electoral
                </p> 
                 
            </b-col>
        </b-row> 

        <div class="item1">
            <b-row>
                <b-col> <legend>1.- DATOS DEL SOLICITANTE</legend>   </b-col>  
            </b-row>
            <hr class="mt-0 mb-0">
            <b-row> 
                <b-col sm="6">
                    <b-form-group label="Run" > 
                    <b-form-input 
                        disabled

                        v-model="formPersonal.run" 
                        type="text" 
                        required
                        ></b-form-input> 
                    </b-form-group>   
                </b-col> 
                <b-col sm="6">
                    <b-form-group label="Nombres" > 
                    <b-form-input 
                        :class="{ 'is-invalid': $v.formPersonal.nombres.$invalid }" 
                        v-model="formPersonal.nombres" 
                        type="text" 
                        required
                        ></b-form-input>
                        <p v-if="!$v.formPersonal.nombres.required" class="invalid-feedback mb-0">
                            El campo Nombres es requerido
                        </p>  
                    </b-form-group>
                    <p></p>   
                </b-col> 
            </b-row>
            <b-row>
               <b-col sm="6">
                    <b-form-group label="Apellido Paterno" > 
                    <b-form-input 
                        :class="{ 'is-invalid': $v.formPersonal.apellido_paterno.$invalid }" 
                        v-model="formPersonal.apellido_paterno" 
                        type="text" 
                        required
                        ></b-form-input>
                        <p v-if="!$v.formPersonal.apellido_paterno.required" class="invalid-feedback mb-0">
                            El campo Apellido paterno es requerido
                        </p>  
                    </b-form-group>   
                </b-col>   
               <b-col sm="6">
                    <b-form-group label="Apellido Materno" > 
                    <b-form-input 
                        :class="{ 'is-invalid': $v.formPersonal.apellido_materno.$invalid }" 
                        v-model="formPersonal.apellido_materno" 
                        type="text" 
                        required
                        ></b-form-input>
                        <p v-if="!$v.formPersonal.apellido_materno.required" class="invalid-feedback mb-0">
                            El campo Apellido materno es requerido
                        </p>  
                    </b-form-group>   
                </b-col>   
            </b-row>
            <b-row>
               <b-col sm="6">
                    <b-form-group label="Fecha Nacimiento" >  
                        <b-form-datepicker 
                            v-model="formPersonal.fecha_nacimiento"
                            :max="max" 
                            :no-flip="true"
                            locale="es">
                        </b-form-datepicker>
                    </b-form-group>   
                </b-col>   
               <b-col sm="6">
                    <b-form-group label="Nacionalidad" >  
                     <b-form-checkbox v-model="formPersonal.is_chileno" switch size="lg">
                         <strong v-if="formPersonal.is_chileno">CHILENA</strong>
                         <strong v-if="!formPersonal.is_chileno">EXTRANJERO</strong>
                     </b-form-checkbox>
                    </b-form-group>   
                </b-col>   
            </b-row>
            <b-row>
                 <b-col sm="6">
                    <b-form-group label="Pais de Nacimiento" >  
                    <model-list-select 
                        :isError="$v.formPersonal.pais_nacimiento.$invalid"
                        v-model="formPersonal.pais_nacimiento"
                        :list="paises_personal"
                        option-value="cod_pais"
                        option-text="pais" 
                        placeholder="seleccionar pais">
                    </model-list-select>  
                    </b-form-group>  
                </b-col> 
            </b-row>  
            
            <DomicilioElectoral 
                v-bind:otrasValidaciones="$v.formPersonal.$invalid"
                v-bind:incorporacion="true" 
                v-bind:ischile="formPersonal.pais_nacimiento" 
                v-on:sendToForm="sendToForm">
            </DomicilioElectoral>     

        </div>   
       
          
    </b-container> 
</div> 
</template>
 
 
<script>  

import { required } from 'vuelidate/lib/validators' 
import axios from 'axios' 
import { ModelListSelect  } from 'vue-search-select' 
import DomicilioElectoral from './partials/DomicilioElectoral'
import router from './../router'
import Swal from 'sweetalert2'  

import { mapState, mapActions } from "vuex"
     

const computed = {
    ...mapState('domicilio', [
        'formChileUrbano',
        'formChileRural',
        'formExtranjero',
        'formComun',
        'ubicacion',
        'paises_personal',
        `paises`,
        `regiones`,
        `territorios`,
        `circunscripciones`,
        `ciudades`,
        `comunas`,
        ]), 
} 

const watch =  {

        'formPersonal.is_chileno': async function(val)
        {
            this.chanchePaisPersonal(val)

            if(val) this.formPersonal.pais_nacimiento = 'CL' 

           if(!val) this.formPersonal.pais_nacimiento = '' 

        },
     
    }


export default {
    name: "Incorporacion",
    components: {  
        ModelListSelect,
        DomicilioElectoral  
    }, 
    computed,
    watch,
    data: function () {


        const now = new Date()
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
 
        const maxDate = new Date(today)
 
        maxDate.setMonth(today.getMonth() - 204) 

    return {  
        max: maxDate,
        loading : true,  
        formPersonal: {
            run: null,
            nombres: null,
            fecha_nacimiento: null,
            apellido_paterno: null,
            apellido_materno: null,
            is_chileno: true,
            pais_nacimiento: null,
        },
       

        }
    },
    validations :{  
        ubicacion: { required },
        formPersonal: {
            run: { required },
            nombres: { required },
            apellido_paterno: { required },
            apellido_materno: { required },
            is_chileno: { required },
            pais_nacimiento: { required },
        }, 
    },
 
    async mounted()
    { 
    
        const run = localStorage.getItem(`CognitoIdentityServiceProvider.${ process.env.VUE_APP_COGNITO_CLIENT_ID }.LastAuthUser`)
        this.formPersonal.pais_nacimiento = 'CL'

        const array_run = run.split('_')
        if(array_run.length >0)
        {
            this.formPersonal.run = array_run[1]
        }


        this.loading = false
    },
    methods: { 
        ...mapActions('domicilio', ['chanchePaisPersonal']),
        ...mapActions('solicitud', ['setIncorporacion', 'setFolio']),
 
        async sendToForm()
        {

            this.loading = true
  

            try {
                const url = `${ process.env.VUE_APP_API_URL }/setsolicitud`

                // https://api.servel.cl/cdomi/setsolicitud
                const payload = {}


                // comunes
                payload.email = this.formComun.email 
                payload.telefono = this.formComun.telefono 
                payload.incorporacion = true
                
 
                payload.calle = null 
                payload.numero = null 
                payload.resto = null
                payload.origen = null 
                payload.cod_ubicacion = null 
                payload.cod_pais = null 
                payload.cod_region = null 
                payload.cod_comuna = null 
                payload.cod_circunscripcion = null
                payload.localidad = null
                payload.cod_consulado = null
                payload.codigopostal = null 


                payload.run = this.formPersonal.run
                payload.fecha_nacimiento = this.formPersonal.fecha_nacimiento
                payload.nombres = this.formPersonal.nombres
                payload.paterno = this.formPersonal.apellido_paterno
                payload.materno = this.formPersonal.apellido_materno
                payload.nacionalidad = this.formPersonal.is_chileno ? 'CHILENO' : 'EXTRANJERO'
                payload.cod_paisnacimiento = this.formPersonal.pais_nacimiento
                payload.paisnacimiento = (this.paises_personal.find(pa => pa.cod_pais == payload.cod_paisnacimiento )).pais


                if(this.ubicacion == 'chile-urbano')
                {
                    payload.cod_pais = 'CL' 
                    payload.pais = 'CHILE' 
                    payload.cod_region = this.formChileUrbano.region 
                    payload.cod_comuna = this.formChileUrbano.comuna 
                    payload.localidad = this.formChileUrbano.villa_poblacion
                    payload.calle = this.formChileUrbano.calle
                    payload.numero = this.formChileUrbano.numero 
                    payload.resto = this.formChileUrbano.block_depto
                    payload.notificacion_correo = this.formChileUrbano.notificacion_correo
                    payload.region = (this.regiones.find(reg=> reg.cod_region == payload.cod_region)).region  
                    payload.comuna = (this.comunas.find(com=> com.cod_comuna == payload.cod_comuna)).comuna 
                    payload.cod_ubicacion = 'U' 
                }
                if(this.ubicacion == 'chile-rural')
                {
                    payload.cod_pais = 'CL' 
                    payload.pais = 'CHILE' 
                    payload.cod_region = this.formChileRural.region 
                    payload.cod_comuna = this.formChileRural.comuna 
                    payload.localidad = this.formChileRural.localidad
                    payload.calle = this.formChileRural.calle
                    payload.numero = this.formChileRural.numero  
                    payload.notificacion_correo = this.formChileUrbano.notificacion_correo
                    payload.region = (this.regiones.find(reg=> reg.cod_region == payload.cod_region)).region  
                    payload.comuna = (this.comunas.find(com=> com.cod_comuna == payload.cod_comuna)).comuna 
                    payload.cod_ubicacion = 'R' 
                }

                if(this.ubicacion == 'extranjero')
                {
                    
                    payload.cod_pais = this.formExtranjero.pais      
                    payload.pais = (this.paises.find(pa=> pa.cod_pais == payload.cod_pais)).pais                  
                    payload.cod_region = this.formExtranjero.division_territorial 
                    payload.region = (this.territorios.find(reg=> reg.cod_region == payload.cod_region)).region  
                    payload.cod_comuna = this.formExtranjero.ciudad 
                    payload.comuna = (this.ciudades.find(com=> com.cod_comuna == payload.cod_comuna)).comuna 
                    payload.calle = this.formExtranjero.calle
                    payload.numero = this.formExtranjero.numero  
                    payload.codigopostal = this.formExtranjero.codigo_postal 
                    payload.cod_circunscripcion = this.formExtranjero.circunscripcion
                    payload.circunscripcion = (this.circunscripciones.find(cir=> cir.cod_circunscripcion == payload.cod_circunscripcion)).circunscripcion 
                    payload.notificacion_correo = true
                    payload.cod_ubicacion = 'E'  

                }
 

                // console.log("LO QUE ENVIO:::::", payload);
 
                

                const { data: {  folio, statusCode, error}} = await  axios.post(url, payload)
                
             
                if(error) throw error

                this.setIncorporacion(true)

                this.setFolio(folio)

            
                router.push({path: '/success'})
                

            } catch (error) {
                console.error("Error al enviar el formulario:: ", error)
                this.loading = false 
                return Swal.fire({
                    icon: 'error',
                    type: 'error',
                    text: error,
                    confirmButtonText: 'Aceptar',
                })
            }
        } 
    }
}
</script>

<style>
</style>
