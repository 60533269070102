import axios from 'axios'
import router from './router'  

axios.interceptors.request.use(async function(config) {

    // auth.calcula_expiracion()

    if(config['headers']['pasar']=='')
    {
        config['headers']['Content-Type'] = `application/json`
        config['headers']['Accept'] = 'application/json; charset=utf-8'
     
        return config
    }


    config['headers']["x-api-key"] = `${process.env.VUE_APP_COGNITO_API_KEY}` 

    if(config['headers']["Authorization"]) return config

    const run = localStorage.getItem(`CognitoIdentityServiceProvider.${ process.env.VUE_APP_COGNITO_CLIENT_ID }.LastAuthUser`)

    const auth_token = localStorage.getItem(`CognitoIdentityServiceProvider.${ process.env.VUE_APP_COGNITO_CLIENT_ID }.${ run }.idToken`);
    
    if(auth_token)
    {
        config['headers']["Authorization"] = `Bearer ${auth_token}` 

    }
    
    config['headers']['Content-Type'] = `application/json`
    config['headers']['Accept'] = 'application/json; charset=utf-8'
 
    return config

}, function(err) {   
    return Promise.reject(err) 
});

axios.interceptors.response.use(
    function (response) 
    { 
        
        if(response.data.statusCode == 403) 
            return router.push({path: '/unauthorized'}) 
        
        if(response.data.statusCode == 401)
            return router.push({path: '/unauthorized'}) 

        if (response.status === 200 || response.status === 201) 
        return Promise.resolve(response)
        

       
        return Promise.reject(response)
    },
    function (error) 
    {   

        if (error.request.status !== 401) 
            return new Promise((resolve, reject) => reject(error))

        if ([401, 403].indexOf(error.request.status) !== -1) 
        {
            router.push({path: '/logout'})
        }

        return Promise.reject(error)
    }
  );

