<template>
  <div>
      <Header></Header>
      <router-view/>
      <Footer></Footer>
  </div>
</template>

<script>
 
import "../../assets/estilo.css" 
import Header from './../partials/Header'
import Footer from './../partials/Footer'

export default {
  name: "Layout-externo1",

   components: {
        Header,
        Footer
    }  
}
</script>

<style>
</style>
