<template>
  <div>

 
    <footer class="py-1 bg-dark">
      <div class="container">
        <p class="m-0 text-center text-white">
          Servicio Electoral {{ version }}
        </p>
      </div> 
    </footer>
  </div>
</template>

<style scoped>

footer {
  position: fixed;
  height: 60px;
  bottom: 0;
  width: 100%;
  z-index: 9;
}

</style>
<script> 


export default {
  name: "Footer",
  data: function () {
    return {
      version: process.env.VUE_APP_VERSION
    }
  }
};
</script>

<style>
</style>
