<template>  
<div>
     <b-container v-if="loading" class="text-center" fluid>
        <b-spinner variant="primary" label="Spinning"></b-spinner>
    </b-container>
</div> 
</template>
 
<script>  

import auth from './../app/auth'
 
export default {
    name: "Callback",
    data: function () { return {  loading : true } },
    async mounted(){
        var currUrl = window.location.href
        auth.auth.parseCognitoWebResponse(currUrl)

    }
}
 
</script>

<style>
</style>
