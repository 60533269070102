<template>  
<div >
    <b-container>
        <b-row>
            <b-col class="text-center">
                <strong>
                    USUARIO NO AUTHORIZADO
                </strong>
            </b-col>

        </b-row>

    </b-container>
</div> 
</template>
 
<script>  
 
export default {
    name: "Unauthorized",
}
 
</script>

<style>
</style>
