<template>
  <div>
    <b-container v-if="loading" class="text-center">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </b-container>
    <b-container v-if="!loading" class="conta">
      <b-row>
        <b-col>
          <p class="h2">Solicitud de Cambio de Domicilio Electoral</p>
        </b-col>
      </b-row>
      <DomicilioElectoral
        v-bind:otrasValidaciones="false"
        v-on:sendToForm="sendToForm"
      >
      </DomicilioElectoral>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { format } from "rut.js";
import { mapState, mapActions } from "vuex";
import DomicilioElectoral from "./partials/DomicilioElectoral";
import router from "../router";

const computed = {
  ...mapState("registro", ["registro"]),
  ...mapState("domicilio", [
    "formChileUrbano",
    "formChileGeo",
    "formChileRural",
    "formExtranjero",
    "formComun",
    "ubicacion",
    `paises`,
    `regiones`,
    `territorios`,
    `circunscripciones`,
    `ciudades`,
    `comunas`,
    `geo_new`,
  ]),
};

export default {
  name: "Solicitar",
  components: {
    DomicilioElectoral,
  },
  computed,
  data: function () {
    return {
      loading: true,
    };
  },
  async mounted() {
    this.loading = false;
  },
  methods: {
    ...mapActions("solicitud", ["setCdomicilio", "setFolio"]),

    formatDireccion() {
      let domicilio = "";

      if (this.registro.calle) domicilio = `${this.registro.calle}`;
      if (this.registro.numero) domicilio += ` ${this.registro.numero}`;
      if (this.registro.resto) domicilio += ` ${this.registro.resto}`;
      if (this.registro.nombre_comuna)
        domicilio += `, ${this.registro.nombre_comuna}`;
      if (this.registro.nombre_region)
        domicilio += `, ${this.registro.nombre_region}`;

      domicilio += ` CIRCUNSCRIPCIÓN : ${this.registro.nombre_circuns}`;

      return domicilio;
    },
    formatNombre() {
      if (!this.registro) return "No existe usuario ";

      let nombre = ``;

      if (this.registro.paterno) nombre += this.registro.paterno;
      if (this.registro.materno) nombre += " " + this.registro.materno;
      if (this.registro.nombre) nombre += " " + this.registro.nombre;

      return nombre;
    },
    formatRun() {
      if (!this.registro) return "No existe usuario ";

      return format(`${this.registro.cedula}-${this.registro.dv}`);
    },
    async sendToForm() {
      try {
        const url = `${process.env.VUE_APP_API_URL}/setsolicitud`;
        const payload = {};
        // comunes
        payload.email = this.formComun.email;
        payload.telefono = this.formComun.telefono;
        payload.incorporacion = false;

        payload.calle = null;
        payload.numero = null;
        payload.resto = null;
        payload.origen = null;
        payload.cod_ubicacion = null;
        payload.cod_pais = null;
        payload.cod_region = null;
        payload.cod_comuna = null;
        payload.cod_circunscripcion = null;
        payload.localidad = null;
        payload.cod_consulado = null;
        payload.codigopostal = null;
        payload.nombres = null;
        payload.paterno = null;
        payload.materno = null;
        payload.paisnacimiento = null;
        payload.geo = null;
        payload.origen_coord = null;
        payload.mapa = this.formComun.screenshot;

        let htmlConfirm = `
                    <table class="table striped">
                        <tbody > 
                            `;
        if (this.ubicacion == "chile-geo") {
          payload.cod_region = this.formChileGeo.cod_region;
          payload.cod_comuna = this.formChileGeo.cod_comuna;
          payload.region = this.formChileGeo.region_nom;
          payload.comuna = this.formChileGeo.comuna;
          payload.cod_pais = "CL";
          payload.pais = "CHILE";
          payload.calle = this.formChileGeo.geo_calle;
          payload.numero = this.formChileGeo.geo_numero;
          payload.resto = this.formChileGeo.tipo_residencia
            ? `${this.formChileGeo.tipo_residencia} ${this.formChileGeo.block_depto}`
            : this.formChileGeo.block_depto;
          payload.cod_ubicacion = "UG";
          payload.geo = this.formChileGeo.geo;
          payload.notificacion_correo =
            this.formChileUrbano.notificacion_correo;
          payload.reponse_google = this.formChileGeo.respuesta_google;
          payload.telefono = "+56" + this.formComun.telefono;
          payload.origen_coord = this.formChileGeo.origen;
          /*
                    htmlConfirm+= `
                            <tr>
                                <td>Dirección</td>
                                <td>${ payload.calle }</td>
                            </tr>` */

          htmlConfirm += `
                            <tr>
                                <td>País</td>
                                <td>${this.formChileGeo.direccion_estandar.pais}</td>
                            </tr>`;
          htmlConfirm += `
                            <tr>
                                <td>Región</td>
                                <td>${this.formChileGeo.region_nom}</td>
                            </tr>`;
          htmlConfirm += `
                            <tr>
                                <td>Comuna</td>
                                <td>${this.formChileGeo.comuna}</td>
                            </tr>`;
          htmlConfirm += `
                            <tr>
                                <td>Calle</td>
                                <td>${this.formChileGeo.direccion_estandar.calle}</td>
                            </tr>`;
          htmlConfirm += ` 
                            <tr>
                                <td>Otra Información</td>
                                <td>${payload.resto || ""}</td>
                            </tr>`;

          let notifi = payload.notificacion_correo ? "SI" : "NO";

          htmlConfirm += ` 
                            <tr>
                                <td>Notificación digital</td>
                                <td>${notifi}</td>
                            </tr>`;
        }

        if (this.ubicacion == "chile-urbano") {
          payload.cod_pais = "CL";
          payload.pais = "CHILE";
          payload.cod_region = this.formChileUrbano.region;
          payload.cod_comuna = this.formChileUrbano.comuna;
          payload.localidad = this.formChileUrbano.villa_poblacion;
          payload.calle = this.formChileUrbano.calle;
          payload.numero = this.formChileUrbano.numero;
          payload.resto = this.formChileUrbano.tipo_residencia
            ? `${this.formChileUrbano.tipo_residencia} ${this.formChileUrbano.block_depto}`
            : this.formChileUrbano.block_depto;
          payload.notificacion_correo =
            this.formChileUrbano.notificacion_correo;
          payload.region = this.regiones.find(
            (reg) => reg.cod_region == payload.cod_region
          ).region;
          payload.comuna = this.comunas.find(
            (com) => com.cod_comuna == payload.cod_comuna
          ).comuna;
          payload.cod_ubicacion = "U";
          payload.geo = this.formChileUrbano.geo;
          payload.telefono = "+56" + this.formComun.telefono;
          payload.origen_coord = this.formChileUrbano.origen;
          payload.cod_localidad = this.formChileUrbano.cod_localidad;

          htmlConfirm += `
                            <tr>
                                <td>Región</td>
                                <td>${payload.region}</td>
                            </tr>`;

          htmlConfirm += `
                            <tr>
                                <td>Comuna</td>
                                <td>${payload.comuna}</td>
                            </tr>`;

          htmlConfirm += ` 
                            <tr>
                                <td>Calle</td>
                                <td>${payload.calle || ""}</td>
                            </tr>`;

          htmlConfirm += ` 
                            <tr>
                                <td>Número</td>
                                <td>${payload.numero || ""}</td>
                            </tr>`;

          htmlConfirm += ` 
                            <tr>
                                <td>Otra Información</td>
                                <td>${payload.resto || ""}</td>
                            </tr>`;

          let notifi = payload.notificacion_correo ? "SI" : "NO";

          htmlConfirm += ` 
                            <tr>
                                <td>Notificación digital</td>
                                <td>${notifi}</td>
                            </tr>`;
        }
        if (this.ubicacion == "chile-rural") {
          payload.cod_pais = "CL";
          payload.pais = "CHILE";
          payload.cod_region = this.formChileRural.region;
          payload.cod_comuna = this.formChileRural.comuna;
          payload.localidad = this.formChileRural.localidad;
          payload.calle = this.formChileRural.calle;
          payload.numero = this.formChileRural.numero;
          payload.notificacion_correo =
            this.formChileUrbano.notificacion_correo;
          payload.region = this.regiones.find(
            (reg) => reg.cod_region == payload.cod_region
          ).region;
          payload.comuna = this.comunas.find(
            (com) => com.cod_comuna == payload.cod_comuna
          ).comuna;
          payload.cod_ubicacion = "R";
          payload.telefono = "+56" + this.formComun.telefono;
          payload.geo = this.formChileRural.geo;
          payload.origen_coord = this.formChileRural.origen;
          payload.cod_localidad = this.formChileRural.cod_localidad;

          htmlConfirm += `
                            <tr>
                                <td>Región</td>
                                <td>${payload.region}</td>
                            </tr>`;

          htmlConfirm += `
                            <tr>
                                <td>Comuna</td>
                                <td>${payload.comuna}</td>
                            </tr>`;

          htmlConfirm += ` 
                            <tr>
                                <td>Calle</td>
                                <td>${payload.calle || ""}</td>
                            </tr>`;

          htmlConfirm += ` 
                            <tr>
                                <td>Número</td>
                                <td>${payload.numero || ""}</td>
                            </tr>`;

          let notifi = payload.notificacion_correo ? "SI" : "NO";

          htmlConfirm += ` 
                            <tr>
                                <td>Notificación digital</td>
                                <td>${notifi}</td>
                            </tr>`;
        }

        if (this.ubicacion == "extranjero") {
          payload.cod_pais = this.formExtranjero.pais;
          payload.pais = this.paises.find(
            (pa) => pa.cod_pais == payload.cod_pais
          ).pais;
          payload.cod_region = this.formExtranjero.division_territorial;
          payload.region = this.territorios.find(
            (reg) => reg.cod_region == payload.cod_region
          ).region;
          payload.cod_comuna = this.formExtranjero.ciudad;
          payload.comuna = this.ciudades.find(
            (com) => com.cod_comuna == payload.cod_comuna
          ).comuna;
          payload.calle = this.formExtranjero.calle;
          payload.numero = this.formExtranjero.numero;
          payload.codigopostal = this.formExtranjero.codigo_postal;
          payload.cod_circunscripcion = this.formExtranjero.circunscripcion;
          payload.circunscripcion = this.circunscripciones.find(
            (cir) => cir.cod_circunscripcion == payload.cod_circunscripcion
          ).circunscripcion;
          payload.notificacion_correo = true;
          payload.cod_ubicacion = "E";
          payload.geo = this.formExtranjero.geo;
          payload.resto = this.formExtranjero.block_depto;
          payload.telefono = "+" + this.formComun.telefono;

          htmlConfirm += `
                            <tr>
                                <td>País</td>
                                <td>${payload.pais}</td>
                            </tr>`;

          htmlConfirm += `
                            <tr>
                                <td>División Territorial</td>
                                <td>${payload.region}</td>
                            </tr>`;

          htmlConfirm += `
                            <tr>
                                <td>Ciudad</td>
                                <td>${payload.comuna}</td>
                            </tr>`;

          htmlConfirm += ` 
                            <tr>
                                <td>Calle</td>
                                <td>${payload.calle || ""}</td>
                            </tr>`;

          htmlConfirm += ` 
                            <tr>
                                <td>Número</td>
                                <td>${payload.numero || ""}</td>
                            </tr>`;

          htmlConfirm += ` 
                            <tr>
                                <td>Código Postal</td>
                                <td>${payload.codigopostal || ""}</td>
                            </tr>`;

          htmlConfirm += ` 
                            <tr>
                                <td>Nombre del Block / Departamento</td>
                                <td>${payload.resto || ""}</td>
                            </tr>`;
        }

        // console.log("PAYLOAD", payload);

        htmlConfirm += ` 
                            <tr>
                                <td>Correo electrónico</td>
                                <td>${payload.email}</td>
                            </tr> 
                            <tr>
                                <td>Teléfono</td>
                                <td>${payload.telefono}</td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <div>
                                    <b-form-checkbox
                                    id="checkbox-1"
                                    v-model="status"
                                    name="checkbox-1"
                                    value="accepted"
                                    unchecked-value="not_accepted"
                                    >
                                    Declaro que estos datos son correctos y solicito mi cambio de domicilio electoral
                                    </div>
                                    </td>
                            </tr>
                        </tbody>
                    </table>`;

        const { value } = await Swal.fire({
          title:
            "Aún no ha finalizado su trámite. En su solicitud de cambio de domicilio electoral ha ingresado los siguientes datos",
          html: htmlConfirm,
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          buttonsStyling: false,
          confirmButtonText:
            "Confirmar Solicitud de cambio de domicilio electoral.",
          customClass: {
            cancelButton: "btn btn-outline-gobierno mt-2 w-100",
            confirmButton: "btn btn-gobierno mt-2 w-100",
          },
        });

        if (!value) {
          this.loading = false;
          return;
        }

        this.loading = true;

        const {
          data: { folio, error },
        } = await axios.post(url, payload);

        if (error) throw error;

        this.setFolio(folio);

        this.setCdomicilio(true);

        router.push({ path: "/success" });
      } catch (error) {
        this.loading = false;
        console.error("Error al enviar el formulario:: ", error);
        return Swal.fire({
          icon: "error",
          type: "error",
          text: error,
          confirmButtonText: "Aceptar",
        });
      }
    },
  },
};
</script>

<style></style>
