<template>
  <div class="overlay2">
    <div class="overlay back"></div>
    <div class="masthead">
      <div class="masthead-bg"></div>
      <div class="container h-100">
        <div class="row h-100">
          <div class="col-12 my-auto">
            <div class="masthead-content text-white py-5 py-md-0">
              <h2 class="mb-3">
                <font style="vertical-align: inherit">
                  <font style="vertical-align: inherit"></font>
                  {{ name }}
                </font>
              </h2>

              <BtnClaveUnica></BtnClaveUnica>

              <!-- <a class="botonServel" href="/login">Ingresa con ID Servel</a> -->
            </div>
            <br />
            <div class="masthead-content py-5 py-md-0">
              <h3 class="mb-3 text-white" style="display: inline">Quedan</h3>
              <h2 class="mb-3" style="display: inline; color: #d90916">
                {{ dias }}
              </h2>
              <h3 class="mb-3 text-white" style="display: inline">
                día(s) para realizar el cambio de domicilio electoral
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="social-icons">
      <img src="../assets/logo-servel.png" style="max-height: 100px" />
    </div>
  </div>
</template>

<script>
import "../assets/coming-soon.css";
import "../assets/estilo.css";
import BtnClaveUnica from "./partials/btn-clave-unica";
import { gmapApi } from "vue2-google-maps";
import axios from "axios";
import moment from "moment";

moment.locale("es");
export default {
  components: {
    BtnClaveUnica,
  },
  computed: {
    google: gmapApi,
  },
  async created() {
    this.$store.commit("SET_LAYOUT", "LayoutLogout");
  },
  mounted() {
    // At this point, the child GmapMap has been mounted, but
    // its map has not been initialized.
    // Therefore we need to write mapRef.$mapPromise.then(() => ...)
    /*
          var config = {
            this.$refs.mapRef.$mapPromise.then((map) => {
              map.panTo({lat: 1.38, lng: 103.80})
            })
            method: 'get',
            url: 'https://maps.googleapis.com/maps/api/place/autocomplete/json?input=Vict&types=geocode&language=es&key=AIzaSyDrgf8gIuNVkLOXuikL5ReFad7wEhx_Wj4',
            headers: {'pasar':''}
          };

          axios(config)
          .then(function (response) {
            console.log(JSON.stringify(response.data));
          })
          .catch(function (error) {
            console.log(error);
          });
*/
    this.dias = this.getDias();
  },
  data: function () {
    return {
      version: process.env.VUE_APP_VERSION,
      name: process.env.VUE_APP_NAME,
      dias: 0,
    };
  },
  methods: {
    getDias() {
      const a = moment([2025, 5, 28]);
      const b = moment(new Date());
      return a.diff(b, "days");
    },
  },
};
</script>
<style>
.back {
  background-image: url(../assets/background.jpg);
  background-size: cover;
  background-position: bottom;
}

.overlay2 {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}
</style>
