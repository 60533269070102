<template>
    <div> 
        <div id="cabecera">
        <nav class="navbar navbar-expand-lg navbar-dark bg-dark static-top">
        <div class="container">
          <img class="logo" src="../../assets/logo-servel.png" />
          <a class="navbar-brand" href="#"> </a>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarResponsive"
            aria-controls="navbarResponsive"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button> 
          <div class="collapse navbar-collapse" id="navbarResponsive">
            <ul class="navbar-nav ml-auto">
              <li class="nav-item active">
                <a class="nav-link" href="https://www.servel.cl/">
                  Servel
                  <span class="sr-only">(current)</span>
                </a>
              </li>
              <li v-if="user.state.loggedIn">
                <b-form-group >
                    <b-button @click="logout"  variant="danger">Cerrar sesión</b-button>
                </b-form-group> 
              </li>  
            </ul>
          </div>
        </div>
      </nav>
    </div>

    </div>
</template>

<style scoped>
    #cabecera {
        margin-bottom: 40px ;
    }
</style>

<script> 
import UserInfoStore  from '../../app/user-info-store'
import auth from './../../app/auth'
import router from './../../router'

export default {
    name: "Header",

    data: function () {
    return { 
        user: null
    }
    },
    methods: 
    {
         
        async logout(){

            await auth.logoutClaveUnica()  
        }


    },
    async created(){

        this.user = UserInfoStore

    }
 
};
</script>

<style>
</style>
