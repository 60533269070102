/* eslint-disable */
import Vue from 'vue'
import Router from 'vue-router' 
import Solicitar from '@/components/Solicitar'
import Solicitudes from '@/components/Solicitudes'
import Incorporacion from '@/components/Incorporacion'
import Unauthorized from '@/components/Unauthorized'
import Callback from '@/components/Callback'
import auth from '../app/auth'
import LogoutSuccess from '@/components/LogoutSuccess' 
import ErrorComponent from '@/components/Error'
import RedirectComponent from '@/components/Redirect'
import Success from '@/components/Success' 
import {is_auth} from './../middleware/is_auth'
import {solicitud_success } from './../middleware/solicitud_success'
import {solicitud_incorporacion } from './../middleware/solicitud_incorporacion'
import {user_registro} from './../middleware/user_registro' 

Vue.use(Router)

 

function multiple(guards) 
{
	return (to, from, next) => {
		const stack = [].concat(guards);
		function another() {
			const guard = stack.pop();
			guard ? guard(to, from, another) : next()
		}
		another();
	}
}

export default new Router({
  mode: 'history',
  base: '/',
  routes: [
    {
      path: '/solicitar',
      name: 'solicitar',
      beforeEnter: user_registro, 
        component: Solicitar
    }, 
    {
      path: '/solicitudes',
      name: 'Solicitudes', 
      beforeEnter: user_registro,
      component: Solicitudes
    }, 
    {
      path: '/redirect',
      beforeEnter: is_auth,
      name: 'Redirect',  
      component: RedirectComponent
    },
    {
      path: '/unauthorized',
      name: 'Unauthorized',  
      component: Unauthorized
    },
    {
      path: '/Success',
      name: 'Success', 
      beforeEnter: multiple([is_auth, solicitud_success]), 
      component: Success
    },
    {
        path: '/', component: LogoutSuccess,  async beforeEnter(to, from, next){
    
            next();
        }
  
      },
    {
      path: '/login', beforeEnter(to, from, next){
        auth.auth.getSession();
      }
    },
    {
      path: '/oauth2', component: Callback
    },
    {
      path: '/logout', component: LogoutSuccess,  beforeEnter(to, from, next){
        
        next();
      }

    },
    {
      path: '/auth/login', component: LogoutSuccess,  beforeEnter(to, from, next){
        next();
      }

    },
    {
      path: '/error', component: ErrorComponent
    },
    {
      path: '*/*', beforeEnter(to, from, next){
       
        next({path: 'error', query: {message: 'pagina no encontrada'}});
      }
    },
  ]
})