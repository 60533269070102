

const state = {
    registro : null
}

const mutations = { 
    SET_REGISTRO(state, payload) { state.registro = payload },   

}

const actions = {

    async setRegistro(state, registro) 
    {   
        state.commit('SET_REGISTRO', registro)    
    
    }
}



export default {
    namespaced: true, 
    state,
    mutations,
    actions
}