

const state = {
    domicilios : [],
    calles : [] 
}

const mutations = { 
    SET_DOMICILIOS(state, payload) { state.domicilios = payload },   
    SET_CALLES(state, payload) { state.calles = payload }
}

const actions = {

    async setDomicilios(state, domicilios) 
    {    
        state.commit('SET_DOMICILIOS', domicilios)    
    
    },
    async setCalles(state, calles )
    { 
        state.commit('SET_CALLES', calles)
    }
}



export default {
    namespaced: true, 
    state,
    mutations,
    actions
}