 import store from '../store' 
 import router from '../router'

 

 export const  solicitud_success = async (to, from, next) => {   

    try { 

        if(store.state.solicitud.cdomicilio || store.state.solicitud.incorporacion) 
        {

            return next()
        }
  

        router.push({name: 'Solicitudes'}) 



    } catch (error) {

        console.error('Error al consultar por el usuario en el registro::', error)
        next({ path: '/solicitudes'}) 
    } 

}  