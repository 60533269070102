

const state = {
    incorporacion : false,
    cdomicilio : false,
    folio: null,
    usuario_no_existe: false
}

const mutations = { 
    SET_INCORPORACION(state, payload) { state.incorporacion = payload },   
    SET_CDOMICILIO(state, payload) { state.cdomicilio = payload },   
    SET_FOLIO(state, payload) { state.folio = payload },   
    SET_USUARIO_NO_EXISTE(state, payload) { state.usuario_no_existe = payload },   

}

const actions = {

    async setIncorporacion(state, payload) 
    {   
        state.commit('SET_INCORPORACION', payload)    
    
    },
    async setCdomicilio(state, payload) 
    {   
        state.commit('SET_CDOMICILIO', payload)    
    
    },
    async setFolio(state, payload) 
    {   
        state.commit('SET_FOLIO', payload)    
        
    },
    async setUsuarioNoExiste(state, payload)
    {
        state.commit('SET_USUARIO_NO_EXISTE', payload)    

    }
}



export default {
    namespaced: true, 
    state,
    mutations,
    actions
}