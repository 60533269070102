<template>
    <div>
        <br>
        <b-container> 
            <div class="alert alert-danger text-center">
                <p>
                Usted no se encuentra incorporado en el Registro Electoral, agradeceremos pinche el botón de solicitud de incorporación, para iniciar el trámite
                </p>
                <br>
                
                <p class="text-center">
                    <b-button @click="ir()" variant="outline-primary">
                        Solicitud de Incorporación
                    </b-button>
                </p>
                
                     
            </div> 
        </b-container>
    </div>
</template>
<script>
export default {
    name:'Redirect',
    data: function(){
        return { 
        }
    },
    methods:{
        async ir()
        {
                window.location.href="https://incorporaciones.servel.cl/"
        }
    },
    mounted(){ 
    }
}
</script>