<template>
  <div>
    <a @click="login" class="btn-cu hover-shw">
      <span class="cl-claveunica"></span>
      <span class="texto">Iniciar sesión</span>
    </a>
  </div>
</template>

<style scoped>
/*
	BTN Clave Única.
	Estilos gráficos para el botón de clave única.
	V 1.0.0
	por David Barra, DGD.
	09-07-2019
*/

.btn-cu {
  display: inline-block;
  padding: 8px 18px 8px 15px !important;
  background-color: #0051a8;
  font-family: Roboto, sans-serif;
  font-size: 1em;
  line-height: 1.6em;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 0;
}

.btn-cu .cl-claveunica {
  color: #fff;
  display: inline-block;
  float: left;
  text-indent: -9999px;
  width: 24px;
  height: 24px;
  /* background: url(icon/claveunica.svg); Esta URL debe ser modificada segun donde quede alojado el documento */
  background: url("./../../assets/icon/claveunica.svg"); /* Esta URL debe ser modificada segun donde quede alojado el documento */
  background-size: 24px 24px;
}

.btn-cu .texto {
  padding-left: 3px;
  /*text-decoration: underline;*/
}

.btn-cu:hover {
  background-color: #0c549c;
  text-decoration: none;
}

.btn-cu:hover.hover-shw {
  background-color: #0f69c4 !important;
  -webkit-box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.2);
}

.btn-cu.s {
  padding: 6px 14px 6px 12px !important;
  font-size: 0.8rem;
}

.btn-cu.s .cl-claveunica {
  width: 18px;
  height: 18px;
  background-size: 18px 18px;
}

.btn-cu.l {
  padding: 11px 30px 11px 23px !important;
  font-size: 1.25rem;
}

.btn-cu.l .cl-claveunica {
  width: 32px;
  height: 32px;
  background-size: 32px 32px;
}

@media only screen and (max-width: 600px) {
  .btn-cu {
    display: flex;
    justify-content: center;
  }
}
</style>
<script>
import auth from "./../../app/auth";
import router from "./../../router";

export default {
  name: "btn-clave-unica",
  methods: {
    async login() {
      this.disabled = true;
      try {
        await auth.logoutClaveUnica();
        router.push({ path: "/login" });
      } catch (error) {
        console.error("Error al ahcer login::", error);
      } finally {
        this.disabled = false;
      }
    },
  },
  data: function () {
    return {
      disabled: false,
      version: process.env.VUE_APP_VERSION,
    };
  },
};
</script>

<style></style>
