<template>
  <div> 
      <router-view/> 
  </div>
</template>

<script>
 
import "../../assets/estilo.css"  

export default {
  name: "Layout-logout",

   components: { 
    }  
}
</script>

<style>
</style>
