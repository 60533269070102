<template>
  <div>
    <b-container v-if="loading" class="text-center" fluid>
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </b-container>
    <b-container v-if="!loading">
      <b-row>
        <b-col class="text-center">
          <p>Su solicitud ha sido recibida correctamente</p>
          <!-- <button type="button" class="btn btn-success btn-lg">Descargar Formulario</button> -->
          <b-button @click="back()" variant="danger" class="mr-1"
            >Volver</b-button
          >

          <b-button @click="download" variant="success">
            Descargar comprobante
          </b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import router from "./../router";
import axios from "axios";
import { mapState, mapActions } from "vuex";
import { seePDFServel } from "./../utils/pdfHelper";

const methods = {
  ...mapActions("solicitud", ["setIncorporacion", "setCdomicilio"]),
  async download() {
    const url = `${process.env.VUE_APP_API_URL}/getcomprobante/${this.folio}/${this.incorporacion}`;

    try {
      this.loading = true;
      const {
        data: { documento, error },
      } = await axios.get(url);

      if (error) throw error;

      await seePDFServel(documento);
    } catch (error) {
      console.error("Error descargar el documento", error);
      this.loading = false;
      console.error("Error al enviar el formulario:: ", error);
      return Swal.fire({
        icon: "error",
        type: "error",
        text: error,
        confirmButtonText: "Aceptar",
      });
    } finally {
      this.loading = false;
    }
  },
  back() {
    router.push({ path: "/solicitudes" });
  },
};

const computed = {
  ...mapState("solicitud", ["incorporacion", "cdomicilio", `folio`]),
};

const mounted = function() {
  if (this.incorporacion) {
    Swal.fire({
      icon: "success",
      type: "sucess",
      text: "Solicitud  de incorporación ingresada de forma correcta",
      confirmButtonText: "Aceptar",
    });
  }

  if (this.cdomicilio) {
    Swal.fire({
      icon: "success",
      type: "sucess",
      text: "Solicitud de cambio de domicilio ingresada de forma correcta",
      confirmButtonText: "Aceptar",
    });
  }

  this.loading = false;
};

export default {
  name: "Success",
  methods,
  computed,
  mounted,
  data: function() {
    return {
      loading: true,
    };
  },
};
</script>

<style></style>
