import Vue from 'vue'
import Vuex from 'vuex'  
import registro from './modules/registro' 
import domicilio from './modules/domicilio'
import solicitud from './modules/solicitud'
import mapa from './modules/mapa'

 
Vue.use(Vuex) 

const modules = {
    registro,
    domicilio,
    solicitud,
    mapa
}

const state = {
    layout: 'LayoutExterno1'

}
const mutations = {
    SET_LAYOUT (state, newLayout) {
      state.layout = newLayout
    }

}

export default new Vuex.Store({
    state,
    mutations,
    modules 
}
)