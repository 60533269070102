<template>
  <div class="content mt-5">
    <b-container v-if="loading" class="text-center" fluid>
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </b-container>
    <b-container v-if="!loading">
      <b-row>
        <b-col>
          <p class="h2">Domicilio Electoral Actual</p>
        </b-col>
        <b-col sm="12">
          <b-alert variant="warning" show>
            <p>
              <b>
                Revise su domicilio electoral actual, con especial cuidado en
                que la comuna sea la correcta.</b
              >
              <b> Si es diferente al actual</b> debe actualizarlo presionando
              “Cambiar domicilio electoral”.
            </p>
            <p>
              Considere que dicho dato corresponde a aquel con el cual usted
              tiene un vínculo, sea porque reside habitual o temporalmente,
              ejerce su profesión u oficio o desarrolla sus estudios en él.
            </p>
          </b-alert>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="item1">
          <b-table
            thead-class="d-none"
            striped
            :fields="['campo', 'dato']"
            :items="datos_electorales"
          ></b-table>
        </b-col>

        <!-- <b-col  sm="6">
    
                        <GmapMap
                        :options="{
                        zoomControl: false,
                        mapTypeControl: false,
                        scaleControl: false,
                        streetViewControl: false,
                        rotateControl: false,
                        fullscreenControl: false,
                        disableDefaultUI: false
                        }"
                        v-if="formChileGeo.geo"
                        :center="{ lat: formChileGeo.geo.position.lat , lng:formChileGeo.geo.position.lng }"
                        :zoom="mapZoom"
                        map-type-id="terrain"
                        ref="map"
                        style="width: 100%; height: 100%; min-height: 350px;"
                        >
                        <GmapMarker
                            :key="index"
                            v-for="(m, index) in marcas"
                            :position="m.position"
                            :clickable="false"
                            :draggable="false"
                            @dragend="gMapFunc($event.latLng)"
                        />
                        </GmapMap> 
                </b-col> -->
      </b-row>
      <b-row style="display: flex; justify-content: space-between">
        <b-col lg="6" sm="12">
          <b-button
            @click="agregarSolicitud()"
            variant="gobierno"
            title="Continuar a la solicitud de cambio de domicilio"
            style="width: 100%"
            class="mt-2"
          >
            Solicitar cambio de domicilio electoral
          </b-button></b-col
        ><b-col lg="6" sm="12">
          <b-button
            @click="openModal()"
            variant="outline-gobierno"
            title="Salir"
            style="width: 100%"
            class="mt-2"
          >
            Salir
          </b-button>
        </b-col>
      </b-row>

      <b-card class="d-none" header-tag="header" footer-tag="footer">
        <template #header>
          <h6 class="mb-0">Solicitudes</h6>
        </template>
        <b-card-text>
          <b-row>
            <b-col sm="12" md="8">
              <b-button
                @click="agregarSolicitud()"
                size="sm"
                variant="success"
                title="Aagregar solicitud"
              >
                <b-icon font-scale="" icon="plus" aria-hidden="true"></b-icon>
                Agregar Solicitud
              </b-button>
            </b-col>
            <b-col sm="12" md="4">
              <b-form-group class="pr-0 mr-0 text-right">
                <b-button
                  size="sm"
                  :disabled="isBusy"
                  @click="actualizar()"
                  variant="primary"
                  >Actualizar</b-button
                >
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col sm="12">
              <b-row>
                <b-col sm="12" md="3" class="my-2 ml-0 pl-0 pr-0">
                  <b-form-group
                    label="por página"
                    label-for="per-page-select"
                    label-cols-md="6"
                    label-cols-sm="12"
                    label-align-sm="left"
                    label-size="sm"
                    class="mb-0 pl-0"
                  >
                    <b-form-select
                      id="per-page-select"
                      v-model="perPage"
                      :options="pageOptions"
                      size="sm"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col md="9" sm="12" class="mr-0 pr-0 pl-0">
                  <b-form-group
                    label="Filtrar"
                    label-for="filter-input"
                    label-cols-sm="3"
                    label-align-sm="right"
                    label-size="sm"
                    class="mb-0"
                  >
                    <b-input-group size="sm">
                      <b-form-input
                        id="filter-input"
                        v-model="filter"
                        type="search"
                        placeholder="Filtrar"
                      ></b-form-input>

                      <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''"
                          >Limpiar</b-button
                        >
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-table
                :items="items"
                :fields="fields"
                :per-page="perPage"
                :filter="filter"
                :filter-included-fields="filterOn"
                :current-page="currentPage"
                stacked="md"
                :busy="isBusy"
                :Striped="true"
                show-empty
                small
                :outlined="true"
                :bordered="true"
              >
                <template #empty="scope">
                  <div class="text-center my-2">
                    No se encontraron resultados
                  </div>
                </template>

                <template #cell(folio)="row">
                  <b-button
                    variant="link"
                    size="sm"
                    @click="download(row.item.folio, false)"
                    class="mr-2"
                  >
                    {{ row.item.folio }}
                  </b-button>
                </template>

                <template #table-busy>
                  <div class="text-center my-2">
                    <b-spinner variant="primary" label="Spinning"></b-spinner>
                  </div>
                </template>
              </b-table>
              <b-row>
                <b-col sm="4" class="my-1"> </b-col>
                <b-col sm="4" class="my-1">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    align="fill"
                    size="sm"
                    class="my-0"
                  ></b-pagination>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card-text>
      </b-card>

      <b-card class="mt-3 d-none" header-tag="header" footer-tag="footer">
        <template #header>
          <h6 class="mb-0">Incorporaciones</h6>
        </template>
        <b-card-text>
          <b-row>
            <b-col sm="12">
              <b-form-group class="pr-0 mr-0 text-right">
                <b-button
                  size="sm"
                  :disabled="isBusyInco"
                  @click="actualizarIncorporacion()"
                  variant="primary"
                  >Actualizar</b-button
                >
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col sm="12">
              <b-row>
                <b-col sm="12" md="3" class="my-2 ml-0 pl-0 pr-0">
                  <b-form-group
                    label="por página"
                    label-for="per-page-select"
                    label-cols-sm="6"
                    label-align-sm="left"
                    label-size="sm"
                    class="mb-0 pl-0"
                  >
                    <b-form-select
                      id="per-page-select"
                      v-model="perPageIncorporacion"
                      :options="pageOptions"
                      size="sm"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col md="9" sm="12" class="mr-0 pr-0 pl-0">
                  <b-form-group
                    label="Filtrar"
                    label-for="filter-input"
                    label-cols-sm="3"
                    label-align-sm="right"
                    label-size="sm"
                    class="mb-0"
                  >
                    <b-input-group size="sm">
                      <b-form-input
                        id="filter-input"
                        v-model="filter"
                        type="search"
                        placeholder="Filtrar"
                      ></b-form-input>

                      <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''"
                          >Limpiar</b-button
                        >
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-table
                :items="itemsIncorporaciones"
                :fields="fieldsIncorporaciones"
                :per-page="perPageIncorporacion"
                :filter="filter"
                :filter-included-fields="filterOn"
                :current-page="currentPageIncorporacion"
                stacked="md"
                :busy="isBusyInco"
                :Striped="true"
                show-empty
                small
                :outlined="true"
                :bordered="true"
              >
                <template #empty="scope">
                  <div class="text-center my-2">
                    No se encontraron resultados
                  </div>
                </template>

                <template #cell(folio)="row">
                  <b-button
                    variant="link"
                    size="sm"
                    @click="download(row.item.folio, true)"
                    class="mr-2"
                  >
                    {{ row.item.folio }}
                  </b-button>
                </template>

                <template #table-busy>
                  <div class="text-center my-2">
                    <b-spinner variant="primary" label="Spinning"></b-spinner>
                  </div>
                </template>
              </b-table>
              <b-row>
                <b-col sm="4" class="my-1"> </b-col>
                <b-col sm="4" class="my-1">
                  <b-pagination
                    v-model="currentPageIncorporacion"
                    :total-rows="totalRowsIncorporaciones"
                    :per-page="perPageIncorporacion"
                    align="fill"
                    size="sm"
                    class="my-0"
                  ></b-pagination>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card-text>
      </b-card>
    </b-container>
  </div>
</template>
<style scoped>
.hidden_header {
  display: none;
}
/* .cl_100 {
        width: 180px !important;
    } */
</style>

<script>
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";
import { seePDFServel } from "./../utils/pdfHelper";
import { mapActions, mapState } from "vuex";
import { gmapApi } from "vue2-google-maps";
import auth from "./../app/auth";

moment.locale("es");

export default {
  name: "Solicitudes",
  components: {},
  data: function () {
    return {
      defaultPosition: { position: { lat: -33.4727092, lng: -70.7699143 } },
      autocomletelocal: "",
      estadoDelAuto: false,
      mapZoom: 12,
      marcas: [],
      datos_electorales: [],
      items: [],
      itemsIncorporaciones: [],
      regiones: [],
      comunas: [],
      filters: {
        id: "",
        issuedBy: "",
        issuedTo: "",
      },
      loading: true,
      isBusy: false,
      isBusyInco: false,
      fields: [
        { key: "folio", label: "Folio", class: "text-center", sortable: true },
        {
          key: "fecha_solicitud",
          label: "Fecha",
          class: "text-center",
          sortable: true,
        },
        {
          key: "nom_origen",
          label: "Origen",
          class: "text-center",
          sortable: true,
        },
        {
          key: "nombre_completo",
          label: "Nombre Solicitante",
          class: "text-center",
          sortable: true,
        },
      ],
      fieldsIncorporaciones: [
        { key: "folio", label: "Folio", class: "text-center", sortable: true },
        {
          key: "fecha_solicitud",
          label: "Fecha",
          class: "text-center",
          sortable: true,
        },
        {
          key: "nom_origen",
          label: "Origen",
          class: "text-center",
          sortable: true,
        },
        {
          key: "nombre_completo",
          label: "Nombre Solicitante",
          class: "text-center",
          sortable: true,
        },
      ],
      totalRows: 0,
      totalRowsIncorporaciones: 0,
      currentPage: 1,
      currentPageIncorporacion: 1,
      perPage: 5,
      perPageIncorporacion: 5,
      pageOptions: [2, 5, 10, 50],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      key: process.env.VUE_APP_GOOGLE,
      service: null,
      geocode: null,
      address: null,
    };
  },
  async created() {
    this.$store.commit("SET_LAYOUT", "LayoutExterno1");

    this.loading = false;
    //this.actualizar()
    //this.actualizarIncorporacion()
  },
  async mounted() {
    const datos = [
      { campo: "RUN", dato: this.getRun() },
      { campo: "Nombre", dato: this.registro.nombre },
      { campo: "Correo Electrónico", dato: this.registro.email },
      {
        campo: "Circunscripción Electoral",
        dato: this.registro.nombre_circuns,
      },
      { campo: "Dirección", dato: this.formatDireccion() },
      { campo: "Comuna", dato: this.registro.nombre_comuna },
      { campo: "Provincia", dato: this.registro.nombre_provincia },
      { campo: "Región", dato: this.registro.nombre_region },
      { campo: "País", dato: this.registro.glosa_pais },
    ];

    this.datos_electorales = datos;
    this.formChileGeo.geo = this.defaultPosition;

    await this.$gmapApiPromiseLazy();

    try {
      const options = {
        scrollwheel: true,
        types: [
          "address_components",
          "formatted_address",
          "geometry.location",
          "icon",
          "name",
        ],
        ComponentRestrictions: { country: ["cl"] },
        gestureHandling: "greedy",
      };

      this.service = await new this.google.maps.places.AutocompleteService(
        options
      );

      this.geocode = await new this.google.maps.Geocoder();
    } catch (error) {
      console.error("Error al instanciar el google auto complete", error);
    }
    let dir =
      this.registro.calle +
      ", " +
      this.registro.numero +
      ", " +
      this.registro.nombre_comuna +
      ", " +
      this.registro.nombre_region +
      ", " +
      this.registro.glosa_pais;
    // this.getGeoCoding(dir)
  },
  computed: {
    google: gmapApi,
    ...mapState("registro", ["registro"]),
    ...mapState("domicilio", [
      "ubicacion",
      "formChileGeo",
      "formChileUrbano",
      "formChileRural",
      "formExtranjero",
      "formComun",
      "paises",
      "ciudades",
      "territorios",
      "circunscripciones",
      "localidades",
    ]),
  },
  methods: {
    ...mapActions("mapa", ["setDomicilios", "setCalles"]),
    ...mapActions("domicilio", ["setCalle"]),

    getRun() {
      return `${this.registro.cedula}-${this.registro.dv}`;
    },
    formatPais() {
      if (!this.registro) return `Sin registro`;

      return this.registro.glosa_pais;
    },
    formatDireccion() {
      let domicilio = "";

      if (!this.registro) return `Sin registro`;

      if (this.registro.calle) domicilio = `${this.registro.calle}`;
      if (this.registro.numero) domicilio += ` ${this.registro.numero}`;
      if (this.registro.resto) domicilio += ` ${this.registro.resto}`;

      return domicilio;
    },
    async actualizarIncorporacion() {
      this.isBusyInco = true;
      await this.getIncorporaciones();
      this.isBusyInco = false;
    },
    async actualizar() {
      this.isBusy = true;
      await this.getSolicitudes();
      this.isBusy = false;
    },
    async getIncorporaciones() {
      const url = `${process.env.VUE_APP_API_URL}/user/solicitudes/incorporacion`;

      try {
        const {
          data: { solicitudes, statusCode, error },
        } = await axios.get(url);

        if (statusCode != 200) throw error;

        if (error) throw error;

        this.totalRowsIncorporaciones = solicitudes.length;

        this.itemsIncorporaciones = solicitudes;
      } catch (error) {
        console.error("Error al consultar por las solicitudes");
        this.loading = false;
        console.error("Error al enviar el formulario:: ", error);
        return Swal.fire({
          icon: "error",
          type: "error",
          text: error,
          confirmButtonText: "Aceptar",
        });
      }
    },
    async getSolicitudes() {
      const url = `${process.env.VUE_APP_API_URL}/user/solicitudes/cambio`;

      try {
        const {
          data: { solicitudes, statusCode, error },
        } = await axios.get(url);

        if (statusCode != 200) throw error;

        if (error) throw error;

        this.totalRows = solicitudes.length;

        this.items = solicitudes;
      } catch (error) {
        console.error("Error al consultar por las solicitudes::", error);
      }
    },
    async agregarSolicitud() {
      window.location = "/solicitar";
    },
    async openModal() {
      if (this.registro.email) {
        await this.salir();
      } else {
        return Swal.fire({
          icon: "info",
          type: "info",
          showCancelButton: true,
          text: "Usted no tiene registrado correo electrónico. ¿Desea incorporarlo para que SERVEL pueda enviarle notificaciones referidas a los procesos eleccionarios y plebiscitarios? En caso de aceptar usted será redirigido al sitio de Notificación Digital",
          confirmButtonText: "Si",
          cancelButtonText: "No",
          cancelButtonColor: "#E13617",
        }).then(async (result) => {
          if (result.value) {
            await this.redirectCorreo();
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            await this.salir();
          }
        });
      }
    },
    async redirectCorreo() {
      window.location.href = "https://notificaciondigital.servel.cl/";
    },
    async salir() {
      await auth.logoutClaveUnica();
    },
    async download(folio, incorporacion) {
      const url = `${process.env.VUE_APP_API_URL}/getcomprobante/${folio}/${incorporacion}`;

      try {
        this.loading = true;
        const {
          data: { documento, error },
        } = await axios.get(url);

        if (error) throw error;

        await seePDFServel(documento);
      } catch (error) {
        console.error("Error descargar el documento", error);
        return Swal.fire({
          icon: "error",
          type: "error",
          text: error,
          confirmButtonText: "Aceptar",
        });
      } finally {
        this.loading = false;
      }
    },
    //2023
    //     async getGeoCoding(query)
    //   {

    //     if(!query) return

    //     try {
    //         this.$emit('g_set_position', {})
    //         await this.geocode.geocode(
    //           {
    //           address: query
    //         },

    //         async (results, status) => {
    //           await this.setCalle(results)
    //           if (status == this.google.maps.GeocoderStatus.OK)
    //           {
    //               this.formChileGeo.geo = {"position": {"lat":results[0].geometry.location.lat(), "lng":results[0].geometry.location.lng()}}
    //               const payload = {}
    //               payload.lat = results[0].geometry.location.lat()
    //               payload.lng = results[0].geometry.location.lng()
    //               payload.respuesta = results[0]
    //               this.$emit('g_set_position', payload)
    //               this.mapZoom= 18
    //               this.marcas = [this.formChileGeo.geo]

    //           } else {
    //             console.error('NO SE LOGRO ENCONTRAR LA DIRECCION::', status)
    //           }
    //         })
    //     } catch (error) {
    //       console.error('Error cosumir geo coding', error)
    //     }

    //     },
  },
};
</script>

<style></style>
