import axios from "axios";
import Swal from "sweetalert2";

const state = {
  ubicacion: "",
  regiones: [],
  comunas: [],
  paises: [],
  paises_personal: [],
  paises_todos: [],
  ciudades: [],
  territorios: [],
  circunscripciones: [],
  localidades: [],
  formChileGeo: {
    cod_comuna: null,
    cod_region: null,
    region: null,
    comuna: null,
    input: null,
    calle: null,
    villa_poblacion: null,
    numero: null,
    block_depto: null,
    geo: null,
    direccion_estandar: null,
    respuesta_google: null,
    tipo_residencia: "",
  },
  formChileUrbano: {
    region: null,
    calle: null,
    comuna: null,
    villa_poblacion: null,
    numero: null,
    block_depto: null,
    notificacion_correo: true,
    geo: null,
    tipo_residencia: "",
  },
  formChileRural: {
    region: null,
    comuna: null,
    localidad: null,
    calle: null,
    numero: null,
    notificacion_correo: true,
  },
  formExtranjero: {
    pais: null,
    division_territorial: null,
    ciudad: null,
    calle: null,
    numero: null,
    codigo_postal: null,
    block_depto: null,
    circunscripcion: null,
    geo: null,
  },
  formComun: {
    email: null,
    email2: null,
    telefono: null,
  },
};

const mutations = {
  SET_REGISTRO(state, payload) {
    state.registro = payload;
  },
  SET_UBICACION(state, payload) {
    state.ubicacion = payload;
  },
  SET_CALLE(state, payload) {
    state.formChileUrbano.calle = payload;
  },
  SET_CALLE_GEO(state, payload) {
    state.formChileGeo.calle = payload.full_calle;
    state.formChileGeo.direccion_estandar = payload.standar;
    state.formChileGeo.geo_calle = payload.nombre;
    state.formChileGeo.geo_numero = payload.numero;
  },
  SET_CALLE_GEO_REGION(state) {
    state.formChileGeo.cod_region = null;
    state.formChileGeo.cod_comuna = null;
    state.formChileGeo.comuna = null;
    state.formChileGeo.region = null;
  },
};

const actions = {
  async chanchePaisPersonal(state, val) {
    if (val) {
      state.state.paises_personal = state.state.paises_todos;
    }

    if (!val) {
      state.state.paises_personal = state.state.paises_todos.filter(
        (p) => p.cod_pais != "CL"
      );
    }
  },
  async setRegistro(state, registro) {
    state.commit("SET_REGISTRO", registro);
  },
  async setCalle(state, geocode) {
    try {
      // console.log("GEOCODE 1::::::", geocode[0].geometry.location.lat(), geocode[0].geometry.location.lng());
      geocode = geocode[0];

      const NO_EXACTOS = ["GEOMETRIC_CENTER", "APPROXIMATE"];
      // console.log("GEOMETRICOS::::.", geocode.geometry);
      if (NO_EXACTOS.includes(geocode.geometry.location_type)) {
        throw { error: "la dirección no es un punto exacto" };
      }
    } catch (error) {
      // Swal.fire({
      //     icon: 'error',
      //     type: 'error',
      //     title: 'La dirección no ha sido encontrada. ',
      //     text: 'Para continuar con tu cambio de domicilio ingresa al formulario manual haciendo clic' ,
      //     confirmButtonText: 'Aquí!',
      //     confirmButtonColor: '#E13617',
      //     }).then(function(result) {
      //         if (result.value) {
      //             state.commit('SET_CALLE_GEO_REGION', null)
      //             state.commit('SET_UBICACION', 'chile-urbano')
      //           }
      //     })

      state.commit("SET_CALLE_GEO_REGION", null);
      state.commit("SET_UBICACION", "chile-urbano");
      throw error;
    }

    try {
      let calle = ``;
      let nombre = geocode.address_components.find(
        (ad) => ad.types[0] == "route"
      ).long_name;

      let numero;
      try {
        numero = geocode.address_components.find(
          (ad) => ad.types[0] == "street_number"
        ).long_name;
      } catch (error) {
        console.error("calle sin numero");
      }
      if (nombre) calle = `${nombre || ""}`;

      if (numero) calle += ` ${numero}`;
      let comuna;
      // console.log("geocode.address_components");
      // const prueba =geocode.address_components.find(ad => ad.types[0] == "locality").long_name
      // console.log("preuba", prueba);
      if (geocode.address_components.find((ad) => ad.types[0] == "locality")) {
        comuna = geocode.address_components.find(
          (ad) => ad.types[0] == "locality"
        ).long_name;
      } else {
        comuna = geocode.address_components.find(
          (ad) => ad.types[0] == "administrative_area_level_3"
        ).long_name;
      }

      let region = geocode.address_components.find(
        (ad) => ad.types[0] == "administrative_area_level_1"
      ).long_name;
      let pais = geocode.address_components.find(
        (ad) => ad.types[0] == "country"
      ).long_name;

      const full_calle = `${calle || ""}, ${comuna || ""}, ${region || ""}, ${
        pais || ""
      }`;
      // const full_calle = `${calle || ''}, ${ comuna || ''}, ${ location || ''}, ${ region || ''}, ${ pais || ''}`
      const payload = {};
      payload.standar = { calle: calle, comuna, region, pais };
      payload.full_calle = full_calle;
      payload.nombre = nombre;
      payload.numero = numero;

      state.commit("SET_CALLE_GEO", payload);

      return full_calle;
    } catch (error) {
      Swal.fire({
        icon: "error",
        type: "error",
        text: "La dirección no es valida",
        confirmButtonText: "Aceptar",
      });
      throw error;
    }
  },
  async changeUbicacion(state, payload) {
    state.commit("SET_UBICACION", payload);
  },
  async cleanForm(state) {
    // state.commit('SET_CLEAN')
  },
  async getLocalidad(state, val) {
    const url = process.env.VUE_APP_API_URL_UTIL + "territorios";
    state.state.localidades = [];
    try {
      const payload = {};
      payload.territorio = "localidades";
      payload.cod_comuna = val;

      const {
        data: { datos, error },
      } = await axios.post(url, payload);

      if (error) throw error;

      state.state.localidades = datos;
    } catch (error) {
      console.error("Error al consultar por los localidades:: ", error);
    }
  },
  async getPaises(state) {
    const url = process.env.VUE_APP_API_URL_UTIL + "territorios";
    state.state.paises = [];
    state.state.paises_personal = [];
    state.state.paises_todos = [];
    try {
      const payload = {};
      payload.territorio = "paises";

      const {
        data: { datos, error },
      } = await axios.post(url, payload);

      if (error) throw error;

      state.state.paises_todos = datos;
      state.state.paises_personal = datos;
      state.state.paises = datos.filter((p) => p.cod_pais != "CL");
    } catch (error) {
      console.error("Error al consultar por los paises:: ", error);
    }
  },
  async getTerritorios(state, val) {
    const url = process.env.VUE_APP_API_URL_UTIL + "territorios";

    state.state.territorios = [];
    try {
      const payload = {};
      payload.territorio = "regiones";
      payload.cod_pais = val;

      const {
        data: { datos, error },
      } = await axios.post(url, payload);

      if (error) throw error;

      state.state.territorios = datos;
    } catch (error) {
      console.error("Error al consultar por los regiones:: ", error);
    }
  },
  async getCiudad(state, val) {
    const url = process.env.VUE_APP_API_URL_UTIL + "territorios";
    state.state.ciudades = [];
    try {
      const payload = {};
      payload.territorio = "comunas";
      payload.cod_pais = state.state.formExtranjero.pais;
      payload.cod_region = val;

      const {
        data: { datos, error },
      } = await axios.post(url, payload);

      if (error) throw error;

      state.state.ciudades = datos;
    } catch (error) {
      console.error("Error al consultar por los regiones:: ", error);
    }
  },
  async getCircuncripciones(state, val) {
    const url = process.env.VUE_APP_API_URL_UTIL + "territorios";

    try {
      const payload = {};
      payload.territorio = "circunscripciones";
      payload.cod_pais = state.state.formExtranjero.pais;
      payload.cod_comuna = val;

      const {
        data: { datos, error },
      } = await axios.post(url, payload);

      if (error) throw error;

      state.state.circunscripciones = datos;
    } catch (error) {
      console.error("Error al consultar por los regiones:: ", error);
    }
  },
  async getComunas(state, cod_region) {
    const url = process.env.VUE_APP_API_URL_UTIL + "territorios";
    state.state.comunas = [];
    try {
      const payload = {};
      payload.territorio = "comunas";
      payload.cod_pais = "CL";
      payload.cod_region = cod_region;

      const {
        data: { datos, error },
      } = await axios.post(url, payload);

      if (error) throw error;

      state.state.comunas = datos;
    } catch (error) {
      console.error("Error al consultar por los comunas:: ", error);
    }
  },
  async getRegiones(state, val) {
    const url = process.env.VUE_APP_API_URL_UTIL + "territorios";
    state.state.regiones = [];
    try {
      const payload = {};
      payload.territorio = "regiones";
      payload.cod_pais = val;

      const {
        data: { datos, error },
      } = await axios.post(url, payload);

      if (error) throw error;

      // state.state.regiones = datos.sort((x,y) => x.cod_region -y.cod_region)
      state.state.regiones = datos;
    } catch (error) {
      console.error("Error al consultar por los regiones:: ", error);
    }
  },
  async confirmButtonText() {},
  async setComunaNull(state, val) {
    state.state.comunas = val;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
