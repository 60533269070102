<template>
  <div>
    <vue-bootstrap-autocomplete
      :disabled="estadoDelAuto"
      :inputClass="class_calle"
      :minMatchingChars="3"
      :data="calles"
      :serializer="(calles) => calles.description"
      ref="typeahead"
      @hit="selected"
      @input="lookupUser"
      :showAllResults="true"
      backgroundVariant="default"
      placeholder="Ingresa una ubicación"
      v-model="address"
      @blur="blur"
    >
      <template slot="suggestion" slot-scope="{ data }">
        <span class="mt-0 p-0">
          <b-icon variant="secondary" icon="geo-alt-fill"></b-icon>
          <small class="ml-2">
            {{ data.description }}
          </small>
        </span>
      </template>
      <template v-if="formChileGeo.calle" slot="append">
        <b-button @click="clearGeo()" variant="outline-primary"
          >Cambiar</b-button
        >
      </template>
    </vue-bootstrap-autocomplete>
  </div>
</template>
<style>
.form-control.is-invalid {
  border-color: #000000 !important;
  border-radius: 2px;
  background-image: none !important;
}
.form-control.is-invalid:focus {
  border-color: #000000;
  border-radius: 2px;
  box-shadow: none !important;
}
</style>
<script>
import axios from "axios";
import { debounce } from "underscore";
import { mapState, mapActions } from "vuex";
import { gmapApi } from "vue2-google-maps";

// import VueTypeaheadBootstrap from "vue-typeahead-bootstrap";
// import VueBootstrapAutocomplete from "@vue-bootstrap-components/vue-bootstrap-autocomplete";
export default {
  name: "GoogleAutoComplete",
  props: ["class_calle", "estadoDelAuto"],
  components: {
    // VueTypeaheadBootstrap,
    // VueBootstrapAutocomplete,
  },
  computed: {
    google: gmapApi,
    ...mapState("mapa", ["calles"]),
    ...mapState("domicilio", ["formChileGeo"]),
  },
  data: function () {
    return {
      key: process.env.VUE_APP_GOOGLE,
      service: null,
      geocode: null,
      address: null,
    };
  },
  async mounted() {
    await this.$gmapApiPromiseLazy();

    try {
      const options = {
        scrollwheel: true,
        types: [
          "address_components",
          "formatted_address",
          "geometry.location",
          "icon",
          "name",
        ],
        ComponentRestrictions: { country: ["cl"] },
        gestureHandling: "greedy",
      };

      this.service = await new this.google.maps.places.AutocompleteService(
        options
      );

      this.geocode = await new this.google.maps.Geocoder();
    } catch (error) {
      console.error("Error al instanciar el google auto complete", error);
    }
  },
  methods: {
    ...mapActions("mapa", ["setDomicilios", "setCalles"]),
    ...mapActions("domicilio", ["setCalle"]),

    lookupUser: debounce(function (addr) {
      if (/\d/.test(addr)) {
        this.getAddresses(addr);
      }
    }, 1000),

    async clearGeo() {
      this.address = ``;
      this.$emit("clear_input_auto");
    },
    displaySuggestions(predictions, status) {
      if (status !== this.google.maps.places.PlacesServiceStatus.OK) {
        this.searchResults = [];
        return;
      }
      // let calles = predictions.map(prediction => (prediction.description ))
      let calles = predictions;
      this.setCalles(calles);
    },
    async selected($event) {
      // console.log("selecccion de ::::", $event);
      // await this.getGeoCoding($event)
      // await this.getGeoCoding2($event.place_id)
      await this.getGeoCodingPlaces($event.place_id);

      this.address = this.formChileGeo.calle;
    },
    async getGeoCodingPlaces(place_id) {
      // console.log("getGeoCodingPlaces::place_id:::", place_id);
      if (!place_id) return;

      try {
        this.$emit("g_set_position", {});
        await this.geocode.geocode(
          {
            placeId: place_id,
          },
          async (results, status) => {
            await this.setCalle(results);

            if (status == this.google.maps.GeocoderStatus.OK) {
              // console.log("GEO::::::",results[0].geometry.location.lat(), results[0].geometry.location.lng());
              this.formChileGeo.geo = {
                position: {
                  lat: results[0].geometry.location.lat(),
                  lng: results[0].geometry.location.lng(),
                },
              };
              const payload = {};
              payload.lat = results[0].geometry.location.lat();
              payload.lng = results[0].geometry.location.lng();
              payload.respuesta = results[0];
              // console.log(results[0].formatted_address);

              this.$emit("g_set_position", payload);
            } else {
              console.error("NO SE LOGRO ENCONTRAR LA DIRECCION::", status);
            }
          }
        );
      } catch (error) {
        console.error("Error cosumir geo coding", error);
      }
    },
    async getGeoCoding(query) {
      // console.log("ADRESSSS:::", query);
      if (!query) return;

      try {
        this.$emit("g_set_position", {});
        await this.geocode.geocode(
          {
            address: query,
          },
          async (results, status) => {
            await this.setCalle(results);
            // console.log("results[0]",results[0]);
            if (status == this.google.maps.GeocoderStatus.OK) {
              this.formChileGeo.geo = {
                position: {
                  lat: results[0].geometry.location.lat(),
                  lng: results[0].geometry.location.lng(),
                },
              };
              const payload = {};
              payload.lat = results[0].geometry.location.lat();
              payload.lng = results[0].geometry.location.lng();
              payload.respuesta = results[0];

              this.$emit("g_set_position", payload);
            } else {
              console.error("NO SE LOGRO ENCONTRAR LA DIRECCION::", status);
            }
          }
        );
      } catch (error) {
        console.error("Error cosumir geo coding", error);
      }
    },
    async getAddresses(query) {
      // console.log("GETADRESS:::::", query);
      if (!query) return;

      try {
        this.service.getPlacePredictions(
          {
            language: "es-419",
            input: query,
            componentRestrictions: { country: "cl" },
          },
          this.displaySuggestions
        );
      } catch (error) {
        console.error("getAddresses, Error al obtener direcciones:: ", error);
      }
    },
    async getGeocoding2(place_id) {
      if (!place_id) return;
      /* BUSQUEDA DE CALLES A GOOGLE */
      try {
        let urlServicio = `https://maps.googleapis.com/maps/api/geocode/json?place_id=${place_id}&key=${process.env.VUE_APP_GOOGLE}`;

        var config = {
          method: "get",
          url: urlServicio,
          headers: { "Content-Type": "application/json" },
        };

        fetch(urlServicio)
          .then((response) => response.json())
          .then((data) => {
            console.log("data::::", data);
            this.setCalle(data.results);

            if (data.status == "OK") {
              this.formChileGeo.geo = {
                position: {
                  lat: data.results[0].geometry.location.lat,
                  lng: data.results[0].geometry.location.lng,
                },
              };
              const payload = {};
              payload.lat = data.results[0].geometry.location.lat;
              payload.lng = data.results[0].geometry.location.lng;
              payload.respuesta = data.results[0];

              this.$emit("g_set_position", payload);
            } else {
              console.error(
                "NO SE LOGRO ENCONTRAR LA DIRECCION::",
                data.status
              );
            }
            // const location = data.result.geometry.location;
            // console.log(`Latitud: ${location.lat}, Longitud: ${location.lng}`);
          })
          .catch((error) => console.error(error));
      } catch (error) {
        console.error("Error al obtener direcciones:: ", error);
      }
    },
    async blur() {
      this.$emit("blur");
    },
  },
};
</script>
