import UserInfoStore from '../app/user-info-store'
import UserInfoApi from '../app/user-info-api' 
import auth from '../app/auth' 

export function is_auth (to, from, next) {
  
    if (!auth.auth.isUserSignedIn()) {
        UserInfoStore.setLoggedIn(false);
        next({
        path: '/auth/login',
        query: { redirect: to.fullPath }
        });
    } else {
      UserInfoApi.getUserInfo().then(response => {
        UserInfoStore.setLoggedIn(true);
        UserInfoStore.setCognitoInfo(response);
        next();
      });
        
    }
  }
 